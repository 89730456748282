<template>
  <div class="language-selector">
    {{ $t('view.main-page.language') }}:
    <div
      class="language-selector__drop-down"
      @click="toggleExpandableElement('language-selector__options')"
    >
      <div class="language-selector__active-language">
        <span>{{ $i18n.locale }}</span>
        &nbsp;
        <img :src="getImagePath($i18n.locale)" :alt="`${$i18n.locale}`">
      </div>

      <div
        id="language-selector__options"
        class="language-selector__options"
      >
        <div
          v-for="language in languages"
          :key="language"
          class="language-selector__option"
          @click="switchLanguage(language)"
        >
          <span>{{ language }}</span>
          &nbsp;
          <img :src="getImagePath(language)" :alt="`${language}`">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LanguageSelector',
  methods: {
    switchLanguage(language) {
      if (this.$i18n.locale !== language) {
        this.$i18n.locale = language;
        document.documentElement.lang = language;
        document.title = this.$i18n.t('title');
        this.$router.replace({ params: { locale: language } });
      }
    },
    getImagePath(language) {
      return `/images/icons/languages/${language}.png`;
    },
    toggleExpandableElement(expandableElementId) {
      const expandableElement = document.getElementById(expandableElementId);

      if (expandableElement.style.maxHeight) {
        expandableElement.style.maxHeight = null;
        expandableElement.style.padding = '0px';
        expandableElement.style.border = 'none';
      } else {
        expandableElement.style.padding = '5px';
        expandableElement.style.border = '1px solid var(--color-shades-light)';
        expandableElement.style.maxHeight = `${expandableElement.scrollHeight}px`;
        expandableElement.style.bottom = '100%';
      }
    },
  },
  data() {
    return {
      languages: process.env.VUE_APP_I18N_SUPPORTED_LOCALES.split(','),
    };
  },
};
</script>

<style lang="scss" scoped>
.language-selector {
  &__active-language {
    border: 1px solid var(--color-shades-light);
    border-radius: 5px;
    padding: 5px;
  }

  &__drop-down {
    width: 100px;
    display: inline-block;
    position: relative;
    &:hover {
      cursor: url('/cursor_hand.png'), auto;
    }
  }
  &__options {
    width: 100px;
    position: absolute;
    overflow: hidden;
    max-height: 0px;
    border-radius: 5px;
    background-color: var(--color-shades-dark);
    z-index: 2;
  }
  &__option {
    &:hover {
      background-color: var(--color-accent-dark);
    }
  }
}
</style>
