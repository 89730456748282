<template>
  <div id="menuIcon" class="menuIcon">
    <div
      id="menuIconContainer"
      class="menuIconContainer"
      v-bind:class="{ open: isOpen }"
      @click="emitIconState()"
    >
      <div class="menuIconBar1"></div>
      <div class="menuIconBar2"></div>
      <div class="menuIconBar3"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MenuIcon',
  props: {
    isOpen: Boolean,
  },
  methods: {
    emitIconState() {
      this.$emit('changeMenuIconState', !this.isOpen);
    },
  },
};
</script>

<style scoped lang="scss">
.menuIcon {
  display: flex;
  align-items: center;
}

.menuIconContainer {
  display: inline-block;
  &:hover {
    cursor: url('/cursor_hand.png'), auto;
  }
}

.menuIconBar1,
.menuIconBar2,
.menuIconBar3 {
  width: 40px;
  height: 5px;
  background-color: var(--color-main-brand);
  margin: 5px;
  transition: 0.3s;

  &:hover {
    transition: 0.3s;
    filter: brightness(100%);
  }
}

.open {
  .menuIconBar1 {
    transform: translate(0px, 10px) rotate(-45deg);
  }

  .menuIconBar2 {
    opacity: 0;
  }

  .menuIconBar3 {
    transform: translate(0px, -10px) rotate(45deg);
  }
}
</style>
