<template>
  <div class="menu">
    <router-link to="/" class="menu__logo">
      <img src="@/assets/images/logo_menu.png" alt="Begravd logo">
    </router-link>
    <div class="menu__options">
      <router-link
        v-for="menuOption in menuOptions"
        v-bind:key="menuOption"
        :to="{ name: convertMenuOptionToRoute(menuOption) }"
        class="menu__option"
      >
        {{ $t(`headers.${formatStringInKebabCase(menuOption)}`) }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MenuDesktop',
  props: {
    menuOptions: Array,
  },
  methods: {
    formatStringInKebabCase(str) {
      return str.toLowerCase().replace(' ', '-');
    },
    convertMenuOptionToRoute(menuOption) {
      return menuOption.toLowerCase().replace(' ', '-');
    },
  },
};
</script>

<style lang="scss" scoped>
.menu {
  &__option {
    text-decoration: none;
    color: var(--color-main-brand);
    margin: 0 20px 0 20px;

    &:hover {
      color: var(--color-accent-light);
      transition: 0.2s;
    }
  }
}
</style>
