<template>
  <div class="mainPage">
    <header id="header" class="header">
      <Menu />
    </header>
    <main id="mainSection" class="mainSection">
      <router-view />
    </main>
    <footer class="footer">
      <LanguageSelector />
      <Copyright />
    </footer>
  </div>
</template>

<script>
import Menu from '../components/menu/Menu.vue';
import LanguageSelector from '../components/language-selection/LanguageSelector.vue';
import Copyright from '../components/Copyright.vue';

export default {
  name: 'MainPage',
  components: {
    Menu,
    LanguageSelector,
    Copyright,
  },
  methods: {
    switchLocale(locale) {
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale;
        document.documentElement.lang = locale;
        document.title = this.$i18n.t('greeting');
        window.setDocumentLanguageProperties(locale, this.$i18n.t('title'));
        this.$router.replace({ params: { locale } });
      }
    },
    adjustContentToHeaderHeight() {
      const headerHeight = document.getElementById('header').scrollHeight;
      const mainElement = document.getElementById('mainSection');
      mainElement.style.marginTop = `${headerHeight}px`;
    },
  },
  mounted() {
    setTimeout(this.adjustContentToHeaderHeight, 100);
  },
};
</script>

<style lang="scss" scoped>
.mainPage {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.header {
  background-color: var(--color-shades-dark-alternative-1);
  background-image: url("/images/backgrounds/dirt_seamless.jpg");
  background-repeat: repeat;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 2;
}

.mainSection {
  background-image: url("/images/backgrounds/moon.jpg");
  background-position: center;
  background-repeat: no-repeat;
  flex-grow: 1;
  width: 100%;
}

footer {
  background-color: var(--color-shades-dark-alternative-1);
  text-align: center;
  background-image: url("/images/backgrounds/dirt_seamless.jpg");
  background-repeat: repeat;
}
</style>
