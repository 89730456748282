<template>
  <div class="menu">
    <div class="menu__bar">
      <div class="menu__part"></div>
      <router-link to="/" class="menu__logo">
        <img src="@/assets/images/logo_menu.png" alt="Begravd logo">
      </router-link>
      <menu-icon
        class="menu__part"
        v-model:isOpen.sync="menuIsOpen"
        v-on:changeMenuIconState="handleChangeMenuIconState($event)"
      />
    </div>
    <div
      id="expandableMenuOptions"
      class="menu__options"
      @click="toggleMenu('expandableMenuOptions', false)"
    >
      <router-link
        v-for="menuOption in menuOptions"
        v-bind:key="menuOption"
        :to="{ name: convertMenuOptionToRoute(menuOption) }"
        class="menu__option"
      >
        {{ $t(`headers.${formatStringInKebabCase(menuOption)}`) }}
      </router-link>
    </div>
  </div>
</template>

<script>
import MenuIcon from '@/components/menu/MenuIcon.vue';

export default {
  name: 'MenuMobile',
  components: {
    MenuIcon,
  },
  props: {
    menuOptions: Array,
  },
  data() {
    return {
      menuIsOpen: false,
    };
  },
  methods: {
    formatStringInKebabCase(str) {
      return str.toLowerCase().replace(' ', '-');
    },
    convertMenuOptionToRoute(menuOption) {
      return menuOption.toLowerCase().replace(' ', '-');
    },
    handleChangeMenuIconState(event) {
      this.toggleMenu('expandableMenuOptions', event);
    },
    toggleMenu(menuId, open) {
      this.setMenuIsOpen(open);
      this.toggleExpandableElement(menuId);
    },
    setMenuIsOpen(value) {
      this.menuIsOpen = value;
    },
    toggleExpandableElement(expandableElementId) {
      const expandableElement = document.getElementById(expandableElementId);

      if (expandableElement.style.maxHeight) {
        expandableElement.style.maxHeight = null;
      } else {
        expandableElement.style.maxHeight = `${expandableElement.scrollHeight}px`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.menu {

  &__logo img {
    max-width: 100%;
  }

  &__options {
    background-color: var(--color-shades-dark-alternative-1);
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  &__option {
    padding: 5px;
    border-top: 1px solid var(--color-main-brand);
    text-decoration: none;
    color: var(--color-main-brand);

    &:hover {
      background-color: var(--color-accent-dark);
    }
  }

  &__bar {
    margin: 10px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }

  &__part {
    flex-basis: 0;
    flex-grow: 3;
    display: flex;
    justify-content: flex-end;
  }
}
</style>
