<template>
  <nav>
    <MenuDesktop class="menuDesktop" v-bind:menuOptions="menuOptions" />
    <MenuMobile class="menuMobile" v-bind:menuOptions="menuOptions" />
  </nav>
</template>

<script>
import MenuDesktop from './MenuDesktop.vue';
import MenuMobile from './MenuMobile.vue';

export default {
  name: 'Menu',
  components: {
    MenuDesktop,
    MenuMobile,
  },
  data() {
    return {
      menuOptions: [
        'Home',
        'About',
        'Music',
        'Contact',
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 599px) {
  .menuDesktop {
    display: none;
  }
}

@media (min-width: 600px) {
  .menuMobile {
    display: none;
  }
}
</style>
