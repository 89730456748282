<template>
  <p id="copyright" class="copyright dimColor">
    Fredrik Wendel. Copyright &copy; 2021-{{ currentYear() }}
    All rights reserved!
  </p>
</template>

<script>
export default {
  name: 'Copyright',
  methods: {
    currentYear() {
      return new Date().getFullYear().toString();
    },
  },
};
</script>

<style lang="scss" scoped>
.copyright {
  text-align: center;
  font-size: 0.9em;
}
.dimColor {
  filter: brightness(65%);
}
</style>
